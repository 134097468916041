define('ember-element-resize-detector/components/resize-detector', ['exports', 'ember-element-resize-detector/templates/components/resize-detector'], function (exports, _resizeDetector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      _Ember$run = Ember.run,
      scheduleOnce = _Ember$run.scheduleOnce,
      bind = _Ember$run.bind;
  exports.default = Ember.Component.extend({
    layout: _resizeDetector.default,
    tagName: '',
    resizeDetector: service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      scheduleOnce('afterRender', this, this.setup);
    },
    setup: function setup() {
      this.callback = bind(this, this.onResize);
      this.get('resizeDetector').setup(this.get('selector'), this.callback);
    },
    teardown: function teardown() {
      this.get('resizeDetector').teardown(this.get('selector'), this.callback);
    },
    onResize: function onResize(element) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      var $el = Ember.$(element);
      this.sendAction('on-resize', {
        width: $el.width(),
        height: $el.height()
      }, element);
    },
    willDestroyElement: function willDestroyElement() {
      this.teardown();

      this._super.apply(this, arguments);
    }
  }).reopenClass({
    positionalParams: ['selector']
  });
});